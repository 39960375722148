import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import Store from '../../../Store/Store'
import { Box, Typography, Paper, CircularProgress, ToggleButtonGroup, ToggleButton } from '@mui/material'
import RackTable from './Tables/RackTable'
import { useTranslation } from 'react-i18next'
import client from '../../../Config/axiosClient'
import { useSnackbar } from 'notistack'
import { mqtt, iot, auth } from 'aws-iot-device-sdk-v2'
import * as AWS from 'aws-sdk'
import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_IOT_ALLTHINGS_TOPIC,
  AWS_IOT_ENDPOINT,
  AWS_REGION,
} from '../../../Config/awsIoTSettings'

/****** START IMPORTED CODE */
/**
 * AWSCognitoCredentialOptions. The credentials options used to create AWSCongnitoCredentialProvider.
 */
const awsCognitoCredentialOptions = {
  IdentityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
  Region: AWS_REGION,
}

let workstationTopic = ''

/**
 * AWSCognitoCredentialsProvider. The AWSCognitoCredentialsProvider implements AWS.CognitoIdentityCredentials.
 *
 */
export class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
  // private options: AWSCognitoCredentialOptions;
  // private source_provider : AWS.CognitoIdentityCredentials;
  // private aws_credentials : auth.AWSCredentials;
  constructor(expire_interval_in_ms) {
    super()
    this.options = awsCognitoCredentialOptions
    console.log("awsCognitoCredentialOptions")
    console.log(awsCognitoCredentialOptions)
    AWS.config.region = awsCognitoCredentialOptions.Region
    this.source_provider = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsCognitoCredentialOptions.IdentityPoolId,
    })
    this.aws_credentials = {
      aws_region: awsCognitoCredentialOptions.Region,
      aws_access_id: this.source_provider.accessKeyId,
      aws_secret_key: this.source_provider.secretAccessKey,
      aws_sts_token: this.source_provider.sessionToken,
    }
    console.log("this.aws_credentials")
    console.log(this.aws_credentials)

    setInterval(async () => {
      await this.refreshCredentialAsync()
    }, expire_interval_in_ms ?? 3600 * 1000)
  }

  getCredentials() {
    return this.aws_credentials
  }

  async refreshCredentialAsync() {
    return new Promise((resolve, reject) => {
      this.source_provider.get(err => {
        if (err) {
          reject('Failed to get cognito credentials.')
        } else {
          this.aws_credentials.aws_access_id = this.source_provider.accessKeyId
          this.aws_credentials.aws_secret_key = this.source_provider.secretAccessKey
          this.aws_credentials.aws_sts_token = this.source_provider.sessionToken
          this.aws_credentials.aws_region = this.options.Region
          resolve(this)
        }
      })
    })
  }
}

async function connect_websocket(provider) {
  return new Promise((resolve, reject) => {
    const uuid = crypto.randomUUID()
    console.log("uuid")
    console.log(uuid)
    console.log("provider")
    console.log(provider)
    let config = iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
      .with_clean_session(true)
      .with_client_id(`pub_sub_sample(${uuid})`)
      .with_endpoint(AWS_IOT_ENDPOINT)
      .with_credential_provider(provider)
      .with_use_websockets()
      .with_keep_alive_seconds(30)
      .build()

    console.log('Connecting websocket...')
    console.log(AWS_IOT_ENDPOINT)
    const client = new mqtt.MqttClient()

    const connection = client.new_connection(config)
    connection.on('connect', session_present => {
      console.log(`initial connect: ${session_present}`)
      Store.testingDataStore.setKeepWebsocketOpen(true)
      resolve(connection)
    })
    // connection.on('interrupt', error => {
    //   console.log(`Connection interrupted: error=${error}`)
    // })
    // connection.on('resume', (return_code, session_present) => {
    //   console.log(`Resumed: rc: ${return_code} existing session: ${session_present}`)
    // })
    connection.on('disconnect', () => {
      console.log('Disconnected')
      Store.testingDataStore.setKeepWebsocketOpen(false)
    })
    connection.on('error', error => {
      Store.testingDataStore.setKeepWebsocketOpen(false)
      reject(error)
    })
    connection.connect()
  })
}
/******* END IMPORTED */

const WorkstationOverview = observer(() => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const storeRacks = Store.testingDataStore.racks
  const keepWebsocketOpen = Store.testingDataStore.keepWebsocketOpen

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const title = Store.testingDataStore.workstationDisplayName
  const [fetching, setFetching] = useState(false)
  const [layoutColumns, setLayoutColumns] = useState('2')
  const [containerStyle, setContainerStyle] = useState({})
  const [itemStyle, setItemStyle] = useState({})
  const refresh = Store.testingDataStore.refresh

  useEffect(() => {
    let racks = []
    let rackNameArray = []

    function createRackNameArray(workstationData) {
        const rackNameArray = [...new Set(workstationData.map(item => item.Rack[0].Name))]
        setLayout(rackNameArray.length)
        return rackNameArray
    }

    function setLayout(rackNameArrayLength) {
      if (rackNameArrayLength == 1) {
        setContainerStyle({
          display: 'grid',
          gap: 4,
          gridTemplateColumns: '1fr',
          justifyItems: 'center',
        })
        setItemStyle({
          width: 'max-content',
        })
        return
      }
      if (rackNameArrayLength == 2) {
        setContainerStyle({
          display: 'grid',
          gap: 4,
          gridTemplateColumns: '1fr 1fr',
          justifyItems: 'center',
        })
        setItemStyle({
          width: 'stretch',
        })
      }
      if (rackNameArrayLength == 4) {
        setContainerStyle({
          display: 'grid',
          gap: 4,
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          justifyContent: 'center',
        })
        setItemStyle({
          width: 'max-content',
        })
        return
      }
  
    }

    function updateRackNameArray(rackNameArray) {
      return rackNameArray
    }

    function createRacks(rackArray, workstationName) {
      // Create rack arrays
      // This ensures that all locations exist here, even if they are not in the database
      let rackData = []
      for (let rack of rackArray) {
        for (let shelf = 5; shelf > 0; shelf--) {
          // location format must match both here and in updateRacks()
          const location = [workstationName, '.', rack, '.', shelf].join('')
          rackData.push({
            location: location,
            status: '',
            battery: '',
            notifications: '',
            robot: '',
            testbed: '',
            rack: rack,
            shelf: shelf,
            statusArray: [],
          })
        }
      }
      return rackData
    }

    function updateRacks(data) {
      // update entries w/ data from database
      for (const item of data) {
        // location format much match both here and in createRacks()
        const location = [item.Workstation[0].Name, '.', item.Rack[0].Name, '.', item.Shelf[0].Name].join('')
        // if (location.length !== 4) {
        //   enqueueSnackbar(`${t('UnexpectedName')}: ${location}`, { variant: 'warning' })
        // }
        //const status = item.Robot[0].PK_Robot ? 'Not Set' : '' // For code validation purposes only
        const battery = ''

        let robot = '';
        let robotStatus = '';
        if (item.Robot) {
          robot = item.Robot[0]
          const getRobotStatus = () => {
            let stat = ''
            if (item.RobotStatus[0].PK_RobotStatus) {
              stat = item.RobotStatus.find(o => o.PK_RobotStatus === item.RobotStatusProfile[0].FK_RobotStatus).Name
            }
            return stat
          }
          robotStatus = getRobotStatus()
          Object.assign(robot, { status: robotStatus })
        }

        let errorArray = [];
        if (item.Error && item.ErrorProfile) {
          // Ensure that errorArray contains unique values (each PK_Error occurs only once)
          errorArray = [...new Map(item.Error.map(m => [m.PK_Error, m])).values()]
          errorArray.map(error => {
            const errorProfile = item.ErrorProfile.find(o => o.FK_Error === error.PK_Error)
            Object.assign(error, { ErrorProfile: errorProfile })
            return error
          })          
        }

        if (robot.length && errorArray.length) {
          Object.assign(robot, { error: errorArray })
        }
        const testbed = item.Testbed[0]
        const rack = item.Rack[0].Name
        const shelf = item.Shelf[0].Name
        const testbedStatus = item.TestbedStatus.find(
          o => o.PK_TestbedStatus === item.TestbedStatusProfile[0].FK_TestbedStatus
        ).Name
        Object.assign(testbed, { status: testbedStatus })

        // Create an array of statuses
        // sort the statuses by DisplayOrder
        // remove entries that have a duplicate DisplayOrder
        // assumption: each status in RobotStatus and TestbedStatus has a unique DisplayOrder value
        const compareDisplayOrder = (a, b) => {
          if (a.DisplayOrder > b.DisplayOrder) {
            return -1
          }
          if (a.DisplayOrder == b.DisplayOrder) {
            return 0
          }
          if (a.DisplayOrder < b.DisplayOrder) {
            return 1
          }
        }
        let statusArray = []
        if (item.RobotStatus) {
          item.RobotStatus.map(stat => {
            if (stat.PK_RobotStatus) {
              statusArray.push({ type: 'robot', ...stat })
            }
          })  
        }
        item.TestbedStatusProfile.map(stat => {
          if (stat.FK_TestbedStatus) {
            const statusDetails = item.TestbedStatus.find(o => o.PK_TestbedStatus === stat.FK_TestbedStatus)
            statusArray.push({ type: 'testbed', notes: stat.Notes, ...statusDetails })
          }
        })
        statusArray.sort(compareDisplayOrder)
        const uniqueStatusArray = [...new Map(statusArray.map(stat => [stat.DisplayOrder, stat])).values()]

        let notifications = []
        if (uniqueStatusArray.length > 0) {
          notifications = uniqueStatusArray[0].Name
        }

        const populateEntry = entry => {
          // console.log(entry)
          // console.log(location)
          if (entry.location === location) {
            Object.assign(entry, {
              status: robotStatus,
              battery: battery,
              notifications: notifications,
              robot: robot,
              testbed: testbed,
              statusArray: uniqueStatusArray,
              rack: rack,
              shelf: shelf,
            })
          }
          return entry
        }

        racks = racks.map(entry => populateEntry(entry))
      }
    }
    

    async function get_workstation_data(workstationID) {
      try {
        const res = await client
          .get('/workstation/' + workstationID, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .catch(error => {
            console.log(error)
            enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          })
        console.log(res.data)
        return res.data
      } catch (error) {
        throw new Error(error)
      }
    }

    async function fetchData() {
      try {
        setFetching(true)
        // Pull from the database
        const workstationDataResponse = await get_workstation_data(Store.testingDataStore.workstationID)
        console.log('workstationDataResponse')
        console.log(workstationDataResponse)
        workstationTopic = workstationDataResponse.workstation_topic
        const workstationData = workstationDataResponse.merged_data
        rackNameArray = createRackNameArray(workstationData)
        updateRackNameArray(rackNameArray)
        Store.testingDataStore.setRackNameArray(rackNameArray)
        // console.log('Store.testingDataStore.rackNameArray')
        // console.log(Store.testingDataStore.rackNameArray)
        // Create dummy racks - get racks associated with this workstation from database
        racks = createRacks(rackNameArray, Store.testingDataStore.workstationName)
        // Pull from the database
        updateRacks(workstationData)
        // Save everything to the store
        // console.log("racks")
        // console.log(racks)
        Store.testingDataStore.setRacks(racks)
        setFetching(false)
      } catch (error) {
        console.log(error)
        enqueueSnackbar(t('FetchFailed'), { variant: 'error' })
        setFetching(false)
      }
    }
    fetchData()
  }, [idToken, enqueueSnackbar, t, refresh])

  useEffect(() => {

    async function get_workstation_topic(workstationID) {
      try {
        const res = await client
          .get('/workstation/' + workstationID, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .catch(error => {
            console.log(error)
            enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          })
        console.log(res.data)
        return res.data
      } catch (error) {
        throw new Error(error)
      }
    }

    async function getWorkstationTopic() {
      try {
        // Pull from the database
        const workstationTopicResponse = await get_workstation_topic(Store.testingDataStore.workstationID)
        console.log('workstationTopicResponse')
        console.log(workstationTopicResponse)
        Store.testingDataStore.setWorkstationTopic(workstationTopicResponse.workstation_topic)
      } catch (error) {
        console.log(error)
        enqueueSnackbar(t('getWorkstationTopic'), { variant: 'error' })
      }
    }

    const wrapperFunction = async () => {
      const provider = new AWSCognitoCredentialsProvider({
        IdentityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
        Region: AWS_REGION,
      })
      await provider.refreshCredentialAsync()
      // console.log("before fetch")
      await getWorkstationTopic()
      // console.log("Store.testingDataStore.workstationTopic")
      // console.log(Store.testingDataStore.workstationTopic)
      connect_websocket(provider)
        .then(connection => {
          connection.subscribe(Store.testingDataStore.workstationTopic, mqtt.QoS.AtLeastOnce, (topic, payload, dup, qos, retain) => {
            // console.log(topic, dup, qos, retain)
            const decoder = new TextDecoder('utf8')
            let robotData = JSON.parse(decoder.decode(new Uint8Array(payload)))
            const blid = robotData.blid
            const robotStatus = robotData.state.reported
            if (Object.hasOwn(robotStatus, "cleanMissionStatus")) {
              //unnamed shadows
              Store.robotStatusStore.setRobotStatusObject(blid, robotStatus)
            } else {
              //named shadows
              Store.robotStatusStore.setRobotStatusObjectStats(blid, robotStatus)
            }
          })
        })
        .catch(reason => {
          Store.testingDataStore.setKeepWebsocketOpen(false)
          console.log(`Error while connecting: ${reason}`)
        })
    }
    wrapperFunction()
  }, [idToken, storeRacks, keepWebsocketOpen])

  const fetchingTemplate = (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={30} sx={{ m: '2em' }} />
    </Box>
  )

  const handleLayoutChange = (event, newLayoutColumns) => {
    if (newLayoutColumns !== null) {
      setLayoutColumns(newLayoutColumns)
    }
  }

  const rackTemplate = (
    <Box sx={containerStyle}>
      {Store.testingDataStore.rackNameArray.map(rackName => {
        return (
          <Box key={`box-${rackName}`} sx={itemStyle}>
            <RackTable rows={Store.testingDataStore.racks} rackName={rackName} key={`rack-${rackName}`} />
          </Box>
        )
      })}
    </Box>
  )

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {title}
      </Typography>
      {fetching ? fetchingTemplate : rackTemplate}
    </Box>
  )
})

export default WorkstationOverview
